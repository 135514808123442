<template>
  <el-dialog :visible.sync="dialogVisible" width="60%" :close-on-click-modal="false" @close="handleClose">
    <div class="main">
      <el-card class="card-1">
        <el-form :inline="true" :model="form" class="form" size="small">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="姓名:">
                {{form1.customName}}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="手机号:">
                {{form1.tel1}}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="产品类型:">
                {{form1.taskName}}
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card>
        <el-form :inline="true" :model="form1" label-width="100px" class="form1" size="small">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="机构：">
                <el-select @change="changeJiGou" :disabled="onlyLook" v-model="form1.deptId" placeholder="请选择机构">
                  <el-option v-for="item in companyList" :key="item.companyId" :label="item.companyName" :value="item.companyId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="预约类型：" class="outBox-1">
                <div class="outBox">
                  <el-select class="yuyueLeft" v-model="form1.tasktypeCode" :disabled="true" @change="changeAppointType" placeholder="请选择预约类型">
                    <el-option v-for="(item, index) in typeList" :key="index" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="采样方式">
                <el-select class="yuyueRight" :disabled="isDisabled" @change="changeSamplingType" v-model="form1.tasktypeCode1" placeholder="请选择采样方式">
                  <el-option label="到店检测" value="07201"></el-option>
                  <el-option label="上门采样" value="07202"></el-option>
                  <el-option label="上门取样" value="07203"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="预约日期">
                <el-date-picker @change="changeOrderDate" v-model="form1.region10" :picker-options="expireTimeOption" :disabled="onlyLook" placeholder="选择预约日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="预约时间：">
                <el-select v-model="form1.timeCode" :disabled="onlyLook" @change="changeTime">
                  <el-option v-for="(item,index) in workingTime" :key="index" :label="item.timing" :value="item.code"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="医生：">
                <el-input v-if="isHaveDoctor" v-model="form1.docterName" :clearable="true" :disabled="true"></el-input>
                <el-input v-else :clearable="true" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="护士：">
                <!-- <el-input v-if="onlyLook" v-model="form1.nurseName"></el-input> -->
                <el-input v-if="onlyLook" v-model="form1.nurseName" :clearable="true" :disabled="onlyLook"></el-input>
                <el-select @change="changeNurse" v-else :disabled="isShowNurse" v-model="form1.nurseId" placeholder="请选择护士">
                  <el-option label="全部" :value="0"></el-option>
                  <el-option v-for="(item, index) in nurseList" :key="index" :label="item.employeeName" :value="item.employeeId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="营养师：">
                <el-input v-if="onlyLook" v-model="form1.dietitianName" :clearable="true" :disabled="onlyLook"></el-input>
                <el-select :disabled="isShowietitian" v-else v-model="form1.dietitianId" placeholder="请选择营养师">
                  <el-option label="全部" :value="0"></el-option>
                  <el-option v-for="(item, index) in dietitianList" :key="index" :label="item.employeeName" :value="item.employeeId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="运动师：">
                <el-input v-if="onlyLook" v-model="form1.fitcouchName" :clearable="true" :disabled="onlyLook"></el-input>
                <el-select :disabled="ishowFitcouch" v-else v-model="form1.fitcouchId" placeholder="请选择运动师">
                  <el-option label="全部" :value="0"></el-option>
                  <el-option v-for="(item, index) in fitcouchList" :key="index" :label="item.employeeName" :value="item.employeeId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地址：">
                <el-input v-model="form1.address" :clearable="true" :disabled="onlyLook" placeholder="请输入地址"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="form1.havingReport">
              <el-form-item label="问卷调查：">
                <el-button @click="showQuestion">问卷调查</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureCommit">确 定</el-button>
    </span>
    <questionnaire @closeQuestion="closeQuestion" :info="info" v-if="isShowQuestion"></questionnaire>
  </el-dialog>
</template>

<script>
import questionnaire from '@/views/appointmentManagement/components/questionnaire'
import { postRequest, getAllInstitutionsInfo } from '@/api'
import { formatTime } from '@/utils/utils'
export default {
  data() {
    return {
      form: {
        input3: '',
      },
      form1: {
        tasktypeCode: '',
        dietitianId: '',
        fitcouchId: '',
        address: '',
        region10: '',
        tasktypeCode1: '',
        docterName: '',
        nurseName: '',
      },
      choose: {
        customerProductDTO: {},
      },
      typeList: [
        {
          dictionaryName: '面诊',
          dictionaryCode: '071',
        },
        {
          dictionaryName: '采样',
          dictionaryCode: '072',
        },
        {
          dictionaryName: '体检结果解读',
          dictionaryCode: '076',
        },
        {
          dictionaryName: '内部体检',
          dictionaryCode: '077',
        },
        {
          dictionaryName: '外部体检',
          dictionaryCode: '078',
        },
      ],
      doctorSpo: false,
      doctorDis: false,
      doctorNur: false,
      doctorDie: false,
      state: '',
      isHaveDoctor: true,
      isShowQuestion: false,
      dialogVisible: true,
      companyList: [],
      // appointmentList: [],
      startTime: '',
      endTime: '',
      maxEndTime: '',
      appointType: '',
      orderDate: '',
      offTime: '',
      isShowNext: false,
      onTime: '',
      company: '',
      doctorList: [],
      nurseList: [],
      housekeeperList: [],
      dietitianList: [],
      fitcouchList: [],
      isOk: true,
      val: '',
      info: {},
      workingTime: [
        {
          timing: '09:00:00 - 10:00:00',
          code: '1',
        },
        {
          timing: '10:00:00 - 11:00:00',
          code: '2',
        },
        {
          timing: '11:00:00 - 12:00:00',
          code: '3',
        },
        {
          timing: '13:00:00 - 14:00:00',
          code: '7',
        },
        {
          timing: '14:00:00 - 15:00:00',
          code: '4',
        },
        {
          timing: '15:00:00 - 16:00:00',
          code: '5',
        },
        {
          timing: '16:00:00 - 17:00:00',
          code: '6',
        },
      ],
      // pickerOptions: {
      //   disabledDate: (time) => {
      //     return time.getTime() < Date.now();
      //   },
      // },
      expireTimeOption: {
        disabledDate(date) {
          return date.getTime() <= Date.now()
        },
      },
    }
  },
  components: { questionnaire },
  props: ['keyId', 'onlyLook'],
  mounted() {
    if (this.keyId) {
      postRequest('/samplingComputer/querySamplingInfoById', { id: this.keyId }).then(res => {
        console.log(res, '提供id查询结果')
        res.region10 = res.samplingTime
        if (res.catalogCode == 'DP') {
          res.catalogName = '单品'
          this.typeList = [
            {
              dictionaryName: '面诊',
              dictionaryCode: '071',
            },
            {
              dictionaryName: '采样',
              dictionaryCode: '072',
            },
          ]
        } else if (res.catalogCode == 'HJ') {
          res.catalogName = '会籍'
          this.typeList = [
            {
              dictionaryName: '面诊',
              dictionaryCode: '071',
            },
            {
              dictionaryName: '采样',
              dictionaryCode: '072',
            },
          ]
        } else if (res.catalogCode == 'TJ') {
          res.catalogName = '体检'
          this.typeList = [
            {
              dictionaryName: '面诊',
              dictionaryCode: '071',
            },
            {
              dictionaryName: '内部体检',
              dictionaryCode: '077',
            },
            {
              dictionaryName: '外部体检',
              dictionaryCode: '078',
            },
          ]
        }

        this.changeShow(res.tasktypeCode)
        this.form1 = res
        this.changeAppointType222(res.tasktypeCode)
      })
    }
    // 查询机构列表
    getAllInstitutionsInfo({}).then(res => {
      console.log(res, '机构')
      this.companyList = res
    })
    // postRequest('/dict/getDictByCategory/', {dictionaryCategoryCode: '07'}).then(res=>{
    //   console.log(res, '预约类型')
    //   this.appointmentList = res
    // })
  },
  computed: {
    isShowDoctor() {
      return this.onlyLook || this.doctorDis
    },
    isShowNurse() {
      return this.onlyLook || this.doctorNur
    },
    isShowietitian() {
      return this.onlyLook || this.doctorDie
    },
    ishowFitcouch() {
      return this.onlyLook || this.doctorSpo
    },
    isDisabled() {
      return this.isShowNext || this.onlyLook
    },
  },
  methods: {
    handleClose() {
      this.$emit('closeAddAppointment')
    },
    changeShow(val) {
      if (val == '071' || val == '076') {
        this.isHaveDoctor = true
      } else if (val == '072' || val == '077' || val == '078') {
        this.isHaveDoctor = false
      }
    },
    closeQuestion() {
      this.isShowQuestion = false
    },
    showQuestion() {
      let data = {
        userId: this.form1.userId,
        orderId: this.form1.orderId,
        customId: this.form1.customId,
      }
      // let data = {
      //   userId:"29",
      //   customId:"64",
      //   orderId:"690"
      // }
      postRequest('/samplingComputer/queryquescustomer', data)
        .then(res => {
          console.log(res, '调查报告')
          this.isShowQuestion = true
          this.info = res
        })
        .catch(() => {
          this.isShowQuestion = false
        })
    },
    changeTime(val) {
      console.log('mounted')
      // this.form1.docterName = ''
      this.form1.nurseId = 0
      this.form1.dietitianId = 0
      this.form1.fitcouchId = 0
      console.log(val)
      this.workingTime.map(item => {
        if (item.code == val) {
          this.val = item.timing
          this.startTime =
            (this.orderDate ? this.orderDate : this.form1.region10.substring(0, 10)) + ' ' + item.timing.substring(0, 8)
          this.endTime =
            (this.orderDate ? this.orderDate : this.form1.region10.substring(0, 10)) +
            '' +
            item.timing.substring(10, 19)
        }
      })
      this.getResule()
    },
    changeOrderDate(val) {
      // this.form1.docterName = ''
      this.form1.nurseId = 0
      this.form1.dietitianId = 0
      this.form1.fitcouchId = 0
      console.log(this.form1.region10)
      this.$forceUpdate()
      this.orderDate = formatTime(val)
      this.startTime = this.orderDate + ' ' + this.val.substring(0, 8)
      this.endTime = this.orderDate + ' ' + this.val.substring(10, 19)
      this.getResule()
    },
    changeJiGou(val) {
      console.log(val)
      // this.form1.docterName = ''
      this.form1.nurseId = 0
      this.form1.dietitianId = 0
      this.form1.fitcouchId = 0
      this.form1.deptId = val
      this.getResule()
    },
    getResule() {
      if (this.form1.deptId && this.startTime && this.endTime && this.form1.tasktypeCode) {
        let data = {
          companyId: this.form1.deptId,
          startTime: this.startTime,
          endTime: this.endTime,
          employeeDutyName: this.form1.tasktypeCode,
        }
        postRequest('/schedul/querySchedul/', data).then(res => {
          console.log(res, '排班查询')
          this.doctorList = []
          this.nurseList = []
          this.housekeeperList = []
          this.dietitianList = []
          this.fitcouchList = []
          res.map(item => {
            // 医生
            if (item.employeeDuty == '0301') {
              this.doctorList.push(item)
            }
            // 0302 护士
            if (item.employeeDuty == '0302') {
              this.nurseList.push(item)
            }
            // 0303 管家
            if (item.employeeDuty == '0303') {
              this.housekeeperList.push(item)
            }
            // 0304 运动师
            if (item.employeeDuty == '0304') {
              this.fitcouchList.push(item)
            }
            // 0305 营养师
            if (item.employeeDuty == '0305') {
              this.dietitianList.push(item)
            }
            // // 0306 健康管理师
            // if (item.employeeDuty == '0306') {
            //   this.dietitianList.push(item)
            // }
            // // 0307 管理员
            // if (item.employeeDuty == '0307') {
            //   this.dietitianList.push(item)
            // }
          })
        })
      }
    },
    changeAppointType(val) {
      console.log(val)
      this.changeShow(val)
      // this.form1.docterName = ''
      this.form1.tasktypeCode1 = ''
      this.form1.nurseId = 0
      this.form1.dietitianId = 0
      this.form1.fitcouchId = 0
      this.typeList.map(item => {
        if (item.dictionaryCode == val) {
          this.employeeDutyName = item.dictionaryName
        }
      })
      if (val == '071' || val == '076' || val == '077' || val == '078') {
        this.isShowNext = true
      } else {
        this.isShowNext = false
      }
      if (val == '071' || val == '073') {
        this.form1.samplingType = '2'
      } else if (val == '074' || val == '075') {
        this.form1.samplingType = '3'
      } else if (val == '071') {
        this.form1.samplingType = '1'
      }
      this.typeList.map(item => {
        if (item.dictionaryCode == val) {
          this.form1.tasktypeName = item.dictionaryName
        }
      })
      this.appointType = val
      if (val == '072' || val == '073' || val == '077' || val == '078') {
        this.doctorDis = true
        this.doctorDie = true
        this.doctorSpo = true
        this.doctorNur = false
        // this.form1.docterName = ''
        this.form1.dietitianId = ''
        this.form1.fitcouchId = ''
      }
      if (val == '074' || val == '075') {
        this.doctorDis = true
        this.doctorDie = true
        this.doctorSpo = true
        this.doctorNur = true
      }
      if (val == '071' || val == '076') {
        this.doctorDis = false
        this.doctorDie = false
        this.doctorSpo = false
        this.doctorNur = true
        this.form1.nurseId = ''
      }
      if (val == '078') {
        this.doctorNur = true
        this.form1.nurseId = ''
      }
      this.getResule()
    },
    changeAppointType222(val) {
      console.log(val)
      this.typeList.map(item => {
        if (item.dictionaryCode == val) {
          this.employeeDutyName = item.dictionaryName
        }
      })
      if (val == '071' || val == '076' || val == '077' || val == '078') {
        this.isShowNext = true
      } else {
        this.isShowNext = false
      }
      if (val == '071' || val == '073') {
        this.form1.samplingType = '2'
      } else if (val == '074' || val == '075') {
        this.form1.samplingType = '3'
      } else if (val == '071') {
        this.form1.samplingType = '1'
      }
      this.typeList.map(item => {
        if (item.dictionaryCode == val) {
          this.form1.tasktypeName = item.dictionaryName
        }
      })
      this.appointType = val
      if (val == '072' || val == '073' || val == '077' || val == '078') {
        this.doctorDis = true
        this.doctorDie = true
        this.doctorSpo = true
        this.doctorNur = false
        // this.form1.docterName = ''
        this.form1.dietitianId = ''
        this.form1.fitcouchId = ''
      }
      if (val == '074' || val == '075') {
        this.doctorDis = true
        this.doctorDie = true
        this.doctorSpo = true
        this.doctorNur = true
      }
      if (val == '071' || val == '076') {
        this.doctorDis = false
        this.doctorDie = false
        this.doctorSpo = false
        this.doctorNur = true
        this.form1.nurseId = ''
      }
      if (val == '078') {
        this.doctorNur = true
        this.form1.nurseId = ''
      }
      this.getResule()
    },
    changeSamplingType(val) {
      this.$forceUpdate()
      console.log(val)
      if (val == '07203') {
        this.doctorNur = true
      } else {
        this.doctorNur = false
      }
    },
    // // 选择医生
    // changeDoctor(){
    //   if (this.appointType == '071') {
    //     this.form1.dietitianId = ''
    //     this.form1.fitcouchId = ''
    //   }
    // },
    // // 选择营养师
    // changeDietitian(){
    //   if (this.appointType == '071') {
    //     this.form1.doctorId = ''
    //     this.form1.fitcouchId = ''
    //   }
    // },
    // // 选择运动师
    // changeSport(){
    //   if (this.appointType == '071') {
    //     this.form1.doctorId = ''
    //     this.form1.dietitianId = ''
    //   }
    // },
    changeNurse() {},
    querySearchAsync(queryString, cb) {
      console.log(queryString)
      console.log('远程查询')
      let data = {
        customerName: queryString ? queryString : '1',
      }
      postRequest('/customer/queryCustomer', data).then(res => {
        console.log(res)
        for (let i = 0; i < res.length; i++) {
          res[i].value = res[i].customerName
        }
        cb(res)
      })
    },
    // handleSelect(item) {
    //   console.log(item);
    //   let reg = /1(\d{2})\d{4}(\d{4})/g;
    //   item.phone = item.tel.replace(reg,"1$1****$2");
    //   this.choose = item
    // },
    sureCommit() {
      if (this.form1.timeCode == null) {
        this.$message({
          message: '请选择预约时间',
          type: 'warning',
        })
      } else {
        this.form1.id = this.keyId
        this.form1.taskStartTime1 = this.startTime
        this.form1.taskEndTime1 = this.endTime
        postRequest('/samplingComputer/openSampling', this.form1).then(res => {
          console.log(res)
          this.$message({
            message: '任务开启成功',
            type: 'success',
          })
          this.handleClose()
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  text-align: center;
  .el-form--inline {
    margin-right: 0 !important;
  }
}
.form1 {
  margin-top: 20px;
}
.el-date-editor {
  width: 93% !important;
}
.card-1 {
  border-radius: 5px;
  margin-bottom: 20px;
}
.el-input {
  width: 100%;
}
</style>