<template>
  <el-dialog
    title="问卷调查"
    :visible.sync="dialogVisible"
    width="50%"
    :close-on-click-modal="false"
    @close="handleClose">
    <el-card>
      
        <h3>{{info.title}}</h3>
        <div class="detailInfo">
          <div>
            <span>客户姓名:</span><span>{{info.customerName}}</span>
          </div>
          <div>
            <span>客户性别:</span><span>{{info.sexStr}}</span>
          </div>
          <div>
            <span>客户电话:</span><span>{{info.tel}}</span>
          </div>
          <div>
            <span>客户生日:</span><span>{{info.birthday}}</span>
          </div>
          <div v-if="info.customerNo != ''">
            <span>客户编号:</span><span>{{info.customerNo}}</span>
          </div>
        </div>
      <div class="outerBox">
        <div v-for="(item, index) in info.classifys1" :key="index">
          <h4>{{item.titleName}}</h4>
          <div v-for="(i, index1) in item.topicVOS" :key="index1">
            <h5>&nbsp;&nbsp;问:&nbsp;&nbsp;{{i.topicName}}</h5>
            &nbsp;&nbsp;&nbsp;&nbsp;<span class="answer">答:&nbsp;&nbsp;{{i.answers == null ? '' : i.answers.answer.toString()}}</span>
          </div>
        </div>
        <div>
          <h4>{{arr.titleName}}</h4>
          <div v-if="arr.topicVOS != null">
            <div v-for="(i, index1) in arr.topicVOS" :key="index1">
              <h5>&nbsp;&nbsp;问:&nbsp;&nbsp;{{i.topicName}}</h5>
              &nbsp;&nbsp;&nbsp;&nbsp;<span class="answer">答:&nbsp;&nbsp;{{i.answers == null ? '' : i.answers.answer.toString()}}</span>
            </div>
          </div>
          <div v-for="(j, index2) in arr.childrenClassify" :key="index2">
            <h6>&nbsp;&nbsp;{{j.titleName}}</h6>
            <div v-for="(k, index3) in j.topicVOS" :key="index3" class="QandA">
              <div>&nbsp;&nbsp;问: {{k.topicName}}</div>
              <div>&nbsp;&nbsp;&nbsp;&nbsp;答: {{k.answers == null ? '' : k.answers.answer.toString()}}</div>
            </div>
          </div>
        </div>
      </div>
      <p class="sureWord">客户已确认以上信息填写准确</p>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true,
      arr: {},
    }
  },
  props: ['info'],
  mounted () {
      console.log(this.info)
      this.arr = this.info.classifys.slice(-1)[0]
      console.log(this.arr)
      this.info.classifys1 = this.info.classifys
      this.info.classifys1 = this.info.classifys1.slice(0, 2)
  },
  methods: {
    handleClose(){
      this.$emit('closeQuestion')
    },
  }
}
</script>

<style lang="scss" scoped>
.omg {
  .answer {
    margin-left: 32px;
    color: #5967ff;
  }
}
h3 {
  text-align: center;
}
.detailInfo {
  display: flex;
  justify-content: space-between;
}
.QandA {
  margin-bottom: 10px;
}
.sureWord {
  text-align: center;
  color: #999;
}
.QandA, .answer, h5 {
  color: #999;
}
.el-card {
  margin-top: -20px;
}
h5 {
  margin: 0;
}
.outerBox {
  height: 500px;
  overflow-x: hidden;
  &::-webkit-scrollbar{
    display: none;
  }
}
</style>>>>>>>> .merge-right.r405
